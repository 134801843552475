<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>公告列表</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="12"   style="text-align:left;">
                            <div class="searchblock">
                                <el-button type="primary" size="small" @click="SelectMerchant" style="margin-right:10px;" v-if="ismerchant != 1">选择商户</el-button>
                                <el-select v-model="status" style="margin-right:10px;width:150px;" size="small" >
                                    <el-option label="全部" value="0"></el-option>
                                    <el-option label="启用" value="1"></el-option>
                                    <el-option label="停用" value="2"></el-option>
                                </el-select>
                                <el-input v-model="keyword" placeholder="公告名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="add" size="small">添加</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0;"
                                v-loading="load"
                                size="small"
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}">
                    <el-table-column label="所属商户" min-width="200" prop="Merchant">
                        <template #default="scope">
                            <span v-if="scope.row.Merchant">{{scope.row.Merchant}}</span>
                            <span v-else>深圳市闲牛科技有限公司</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="公告名称" min-width="200" prop="Title"></el-table-column>
                    
                    <el-table-column label="创建时间" width="200" prop="AddDTime" :formatter="Getdate" sortable></el-table-column>
                    <el-table-column label="更新时间" width="200" prop="UpdateDTime" :formatter="Getdate" sortable></el-table-column>
                    <el-table-column label="状态" width="200">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0"><span style="color:red">停用</span></span>
                            <span v-if="scope.row.Status == 1">启用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="250">
                        <template #default="scope">
                            <el-button type="text" style="margin-right:20px;" @click="Detail(scope.row.ID)">详情</el-button>
                            <el-button v-if="scope.row.Status == 0" type="text"  @click="Enable(scope.row.ID)" style="margin-right:20px;">启用</el-button>
                            <el-button v-if="scope.row.Status == 1" type="text"  @click="Disable(scope.row.ID)" style="margin-right:20px;">停用</el-button>
                            <el-button type="text"  @click="Edit(scope.row.ID)" :disabled="scope.row.Status == 0?false:true" style="margin-right:20px;">
                                编辑</el-button>
                            <el-button type="text"   @click="Del(scope.row.ID)" :disabled="scope.row.Status == 0?false:true">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    
    <el-dialog v-model="editor" title="编辑公告信息" width="30%">
        <el-form :model="noticeinfo" label-position="left" :rules="rule" ref="noticeinfo" class="noticeform">
            <el-form-item label="所属商户" label-width="100px" prop="MerchantID" v-if="ismerchant != 1">
                <el-button type="primary" size="small" @click="SelectMerchant" style="margin-right:10px;">选择商户</el-button>
            </el-form-item>
            <el-form-item label="公告标题" label-width="100px" prop="noticename">
                <el-input v-model="noticeinfo.noticename"></el-input>
            </el-form-item>
            
            <el-form-item label="上传图片" prop="noticepic" label-width="100px">
                
                <el-upload
                    class="avatar-uploader"
                    :action="upload_url"
                    list-type="picture-card"
                    :on-success="UploadSuccess"
                    :on-change="UploadChange"
                    :on-error="UploadError"
                    :on-remove="Remove"
                    :on-preview="Preview"
                    multiple
                    :limit="3"
                    :on-exceed="UploadExceed"
                    :file-list="FileList"
                >
                    <i class="el-icon-plus"></i>
                    <template #tip>
                        <div class="el-upload__tip">只能上传 jpg/png/pdf 文件,最多上传3张</div>
                    </template>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                    <img style="width:100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
            <el-button @click="CloseDialog('noticeinfo')">取 消</el-button>
            <el-button type="primary" @click="submitform('noticeinfo')">确 定</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="detail" title="公告详情图">
        <!--<el-image v-for="url in urls" :key="url" :src="url" lazy></el-image>-->
        <div class="noticelist">
            
            <el-image class="noticeimg" v-for="url in urls" :key="url" :src="url" lazy :preview-src-list="urls"></el-image>
        </div>
    </el-dialog>
    <el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant"
                filterable
                filter-placeholder="请输入商户名称"
                :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<style scoped>
.merchant-block {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.noticelist{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.noticelist .noticeimg {
    width: 150px;
    height: auto;
    padding: 20px;
}
.searchblock{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.noticeform >>> .avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.noticeform >>> .avatar {
  width: 120px;
  display: block;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}
</style>
<script>
import Cookies from 'js-cookie'
import qs from 'qs';
import constant from "@/constant"
import { ElMessage } from 'element-plus'
export default {
    data(){
        return {
            selmerchant:[],
            transfertitle:['商户列表','已选商户'],
            merchantlist:[],
            merchant:false,
            urls:[],
            detail:false,
            dialogImageUrl: '',
            dialogVisible: false,
            uploadbtn: true,
            FileList:[],
            upload_url:constant.upload_temp_img_url,
            keyword:'',
            status:'0',
            tabledata:[],
            statuslabel:'停用',
            editor: false,
            noticeinfo:{
                noticename:'',
                id:'',
                status: '0',
                noticepic:"",
            },
            rule:{
                noticename:[{
                    required: true, message: '请输入公告名称',trigger: 'blur'
                }]
            },
            curpage:1,
            pagesize:10,
            totalcount:'',
        }
    },
    methods:{
        ConfirmMerchant(){
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back(){
            this.merchant = false;
        },
        SelectMerchant(){
            this.merchant = true;
        },
        CloseDialog(formName){
            this.editor = false;
            this.$refs[formName].resetFields();
        },
        add(){
            this.noticeinfo.noticename = '';
            this.noticeinfo.noticedesc = '';
            this.noticeinfo.id = '';
            this.editor = true;
        },
        submitform(formName){
            this.$refs[formName].validate((valid)=>{
                if(valid){
                    console.log(JSON.stringify(this.selmerchant));
                    this.axios.post(constant.save_notice_url,qs.stringify(this.noticeinfo),{
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            params:{
                                selmerchant:JSON.stringify(this.selmerchant),
                                ismerchant:this.ismerchant,
                                mid:this.mid,
                            }
                    }).then((response)=>{
                        console.log(response.data);
                        const cur_obj = this;
                        if(response.data == "OK"){
                            cur_obj.editor =false;
                            ElMessage.success({
                                type:'success',
                                message:'操作成功',
                                duration:1000,
                                onClose:function(){
                                    cur_obj.$router.go(0);
                                }
                            });
                        }else{
                            ElMessage.error({
                                type:'error',
                                message:'操作失败',
                                duration:1500,
                            });
                        }
                    })
                }else{
                    return false;
                }
            });
        },
        ChangeLabel(){
            if(this.noticeinfo.status == "0")
                this.statuslabel = '停用';
            if(this.noticeinfo.status == "1")
                this.statuslabel = '启用';
        },
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                return year + "-" + month + "-" + day;
            }
        },
        Edit(id){
            if(!id)
                return false;
            console.log(id);
            this.axios.get(constant.get_notice_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data)
                this.editor = true;
                this.noticeinfo.noticename = response.data.Title;
                this.noticeinfo.id = response.data.ID;
                this.noticeinfo.status = response.data.Status;
                this.noticeinfo.noticepic = response.data.Images;
                if(this.ismerchant != 1)
                    this.selmerchant = response.data.selmerchant;
                if(response.data.Images)
                    this.FileList = response.data.Images;
            });
        },
        Enable(id){
            this.axios.get(constant.enable_notice_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Disable(id){
            this.axios.get(constant.disable_notice_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Del(id){
            if(!confirm("此操作将删除该公告, 是否继续?"))
                return false;
            this.axios.get(constant.del_notice_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                const cur_obj = this;
                if(response.data == "OK"){
                    ElMessage.success({
                        type:'success',
                        message:'删除成功',
                        duration:1000,
                        onClose:function(){
                            cur_obj.$router.go(0);
                        }
                    });
                }
            });
        },
        init(){
            console.log(this.status);
            this.axios.get(constant.get_notice_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    status: this.status,
                    keyword: this.keyword,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    selmerchant:JSON.stringify(this.selmerchant),
                }
            }).then((response)=>{
                console.log(response);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        UploadSuccess(response,file,filelist){
            console.log(file);
            //console.log("list");
            //console.log(filelist);
            //console.log(response);
            if(response.error == '0'){
                //this.pdtinfo.PdtImg.push(response.url);
                this.noticeinfo.noticepic = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if(filelist[i]['response']){
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    }else{
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.noticeinfo.noticepic.push(obj);
                }
                console.log("mylist");
                
                console.log(this.noticeinfo.noticepic);
            }
            
        },
        UploadChange(file){
            console.log(file);
        },
        UploadExceed(file,filelist){
            this.$message.warning(`当前限制选择 3 个图片，本次选择了 ${file.length} 个文件，共选择了 ${file.length + filelist.length} 个文件`);
        },
        Preview(file) {
            console.log(file);
            if(file.response){
                if(file.response.error == 0){
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else{
                this.dialogImageUrl = file.url;
            }
            
            this.dialogVisible = true;
        },
        Remove(file,filelist) {
            console.log(file);
            this.noticeinfo.noticepic = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = filelist[i]['url'];
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.noticeinfo.noticepic.push(obj);
                }
        },
        Detail(id){
            if(!id)
                return false;
            this.axios.get(constant.get_notice_detail,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.urls = response.data;
                this.detail = true;
            });
        },
        
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.get_notice_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                ismerchant:this.ismerchant,
                mid:this.mid
            }
        }).then((response)=>{
            console.log(response.data);
            this.tabledata = response.data.list;
            this.curpage = response.data.curpage*1;
            this.totalcount = response.data.totalcount*1;
        });
        this.axios.get(constant.allmerchant,{
            headers:{
                "Content-Type":'application/json'
            }
        }).then((response)=>{
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for(let i = 0;i<list.length;i++){
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });
    }
}
</script>